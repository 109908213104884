<script setup>

import { ref } from 'vue'
import { ElMessage } from 'element-plus'
// import { useRouter } from 'vue-router'
import {
  loginApi
} from './fetch'

// const router = useRouter()

const formEl = ref(null)
const form = ref({
  username: '',
  password: ''
})

const onSubmit = async () => {
  try {
    await formEl.value.validate()
    await loginApi({
      username: form.value.username,
      password: form.value.password
    })
    ElMessage.success('登录成功！')
    window.location.href = '/'
  } catch (e) {
    ElMessage.error(e.msg || e)
  }
}

// const onRegister = async () => {
//   router.push({ path: '/register' })
// }
</script>

<template>
  <div class="login_wrap">
    <el-form ref="formEl" class="form" label-width="80px">
      <el-form-item label="用户名">
        <el-input v-model="form.username"/>
      </el-form-item>
      <el-form-item label="密码">
        <el-input type="password" v-model="form.password"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">登录</el-button>
        <!-- <el-button plain @click="onRegister">注册</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="stylus" scoped>
.login_wrap
  padding 100px
  .form
    width 460px
    margin 0 auto
</style>
