import Request from '@/utils/request'

export function loginApi (data) {
  return Request({
    url: '/user/login',
    method: 'post',
    data
  })
}

export function registerApi (data) {
  return Request({
    url: '/user/register',
    method: 'post',
    data
  })
}
